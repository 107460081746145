import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {AllExpensesList} from "./AllExpensesList";
import {AllExpensesEdit} from "./AllExpensesEdit";
import {AllExpensesCreate} from "./AllExpensesCreate";

const resource = {
    list: AllExpensesList,
    create: AllExpensesCreate,
    edit: AllExpensesEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;