import * as React from 'react';

import {
    //CloneButton,
    CreateButton,
    FilterButton,
    TopToolbar,
    useTranslate,
    ExportButton,
} from 'react-admin';
import {SelectColumnsButton} from "@react-admin/ra-preferences";
import {ButtonFilterAside} from "./CompReact";
import {Box} from "@material-ui/core";

const TableActions = (
    {
        preference,
        columns,
        basePath,
        data,
        create = false,
        select = true,
        filter = true,
        asideFilter = false,
        exportCsv = false,
        showAsside = true,
        exporterFile = false,
        exporterlabel = 'Export'
    }
) => {

    const translate = useTranslate();
    return (
        <TopToolbar fullWidth>
            <Box display="flex" alignItems={"center"}>
                {asideFilter && <ButtonFilterAside showAsside={showAsside}/>}
                {filter && <FilterButton/>}
                {/*<CloneButton basePath={basePath} record={data} />*/}
                {create && <CreateButton basePath={basePath} record={data}/>}
                {select && <SelectColumnsButton
                    preference={preference}
                    columns={columns}
                    label={translate('myLocal.columns')}
                />}
                {exporterFile && <ExportButton exporter={exporterFile} label={exporterlabel}/>}
                {exportCsv && <ExportButton label={'Export'}/>}
            </Box>
        </TopToolbar>
    )
};
export default TableActions;
