import * as React from 'react';

import {
    BulkDeleteButton,
    BulkExportButton,
} from 'react-admin';
import {Fragment} from "react";

// React-admin предоставляет три компонента, которые вы можете использовать в bulkActionButtons:
// <BulkDeleteButton>, <BulkUpdateButton>и <BulkExportButton>
const PostBulkActionButtons = ({canDelete, exporterlabel, exporterFile,  ...props}) => (

    <Fragment style={{marginLeft: 24}}>
        {exporterlabel && exporterFile &&
            <BulkExportButton exporter={exporterFile} label={exporterlabel} style={{marginLeft: 24}}/>
        }
        <BulkDeleteButton
            {...props}
             style={{marginLeft: 24}}
            undoable={false} //Подтверждение удаления
        />
    </Fragment>
);
export default PostBulkActionButtons;
