import * as React from 'react';
import {AppBar} from 'react-admin';
import {Box, Typography} from '@material-ui/core';
// import {LanguageSwitcher} from "@react-admin/ra-preferences";
// import {localesConfig} from "../utils/config";
// import ScanDialog from "../ScanDialog";

const MyAppBar = props => (
    <AppBar {...props}>
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title">swiss3rcc</Typography>
        </Box>
        {/*<ScanDialog />*/}
        {/*<LanguageSwitcher
            languages={localesConfig.languages}/>*/}
    </AppBar>
);

export default MyAppBar
