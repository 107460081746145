import React, {Fragment} from 'react';
//import {Box} from "@material-ui/core";
//import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, ReferenceField,
    //SelectInput,
    SimpleList,
    TextField,
    useLocale, useRecordContext,
    AutocompleteInput,
    ReferenceInput,
    TextInput,
    SimpleShowLayout,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent, Grid, Tab, Tabs} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../index.css';
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
import {
    DateFieldComp,
    FilterDataAside,
    FloatNumber,
    formatSpacerNumber,
    getConfigFromStorage,
    TextFieldBold,
} from "../_common/CompReact";
import CardComp from "../_common/CardComp";
//import {FloatNumber, formatSpacerNumber} from "../booking/BookingColumnsForList";
import get from "lodash/get";
import {getListYear} from "../utils/choices";
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
import {useEffect} from "react";
//import {useSelector} from "react-redux";
import moment from "moment";
import {ExpCompositions} from "./ExpTablesEditCreate";

const SupportTable = (props) => {
    //const {source = null, column = 'none', fix = 2, delim = ','} = props;
    const {classes, tbl} = props;
    const record = useRecordContext(props);

    let dataTbl = get(record, tbl, null)
    let total = 0;
    let colSpan = 1;
    switch (tbl) {
        case 'others':
            colSpan = 2;
            break
        case 'offices':
            colSpan = 1;
            break
        case 'salaries':
            colSpan = 5;
            break
        case 'deprecations':
            colSpan = 4;
            break
        default:
            colSpan = 1;
            break
    }
    //if (support_others && support_others.length>0) {
    if (dataTbl && dataTbl.length > 0) {
        return (
            <table className="tblInsideTd">
                {dataTbl.map(it => {
                    //console.log('it=====>', it);
                    total += it.contribution;
                    return (
                        <tr>
                            {tbl === 'others'
                                ?
                                <>
                                    <td className={classes.date_st}>{it.date}</td>
                                    <td className={classes.description}>{it.description}</td>
                                    <td className={classes.price_st}>{formatSpacerNumber(it.contribution)}</td>
                                </>
                                : tbl === 'offices'
                                    ?
                                    <>
                                        <td className={classes.months_day}>{it.space_m2}</td>
                                        <td className={classes.price_st}>{formatSpacerNumber(it.contribution)}</td>
                                    </>
                                    : tbl === 'salaries'
                                        ?
                                        <>
                                            <td className={classes.date_st}>{it.date}</td>
                                            <td className={classes.description}>{it.description}</td>
                                            <td className={classes.positionName}>{it.position.name}</td>
                                            <td className={classes.months_day}>{it.months}</td>
                                            <td className={classes.months_day}>{it.days}</td>
                                            <td className={classes.price_st}>{formatSpacerNumber(it.contribution)}</td>

                                        </>
                                        : tbl === 'deprecations'
                                            ?
                                            <>
                                                <td className={classes.date_st}>{it.date}</td>
                                                <td className={classes.description}>{it.description}</td>
                                                <td className={classes.months_day}>{it.deprecation_years}</td>
                                                <td className={classes.price_st}>{formatSpacerNumber(it.total_cost)}</td>
                                                <td className={classes.price_st}>{formatSpacerNumber(it.contribution)}</td>

                                            </>
                                            : <td>ERROR</td>
                            }
                        </tr>
                    )
                })
                }
                <tr>
                    {/*<td colSpan={colSpan} style={{backgroundColor: '#f4f7ff'}}><b>Total</b></td>*/}
                    <td colSpan={colSpan} style={{backgroundColor: '#f4f7ff'}}><b>Total</b></td>
                    <td className={classes.price_st} style={{backgroundColor: '#f4f7ff'}}>{formatSpacerNumber(total)}</td>
                </tr>
            </table>
        )
    } else return null;
}

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch label={'Search'}/>
                <FilterDataAside fields={'created_at'} name={'created'}/>
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<SelectInput source="year" choices={getListYear()} alwaysOn/>);*/
    dt.push(<TextInput label="Search Name" source="name" alwaysOn/>);
    dt.push(<ReferenceInput source="group_id" reference="groups" alwaysOn>
        <AutocompleteInput optionText="name" resettable emptyText={'All Groups'}/>
    </ReferenceInput>);
    return dt;
}
const ColumnsForList = (resource, locale, classes, expenses_type_id) => {
    const {exp_compositions, prop_types_belongs} = getConfigFromStorage(expenses_type_id);

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        name: <TextField source={'name'} label={'Name'}/>,
        /*user_id: <NumberField source={'user_id'} label={'User'}/>,*/
        /*company_id:
            <ReferenceField source="company_id" reference="companies" label={'Company'} link={false}
                            sortBy={'companies:name'}>
                <TextFieldBold source="name"/>
            </ReferenceField>,*/
        group_id: /*<NumberField source={'company_id'} label={'Company'}/>,*/
            <ReferenceField source="group_id" reference="groups" label={'Group'} link={false}
                            sortBy={'groups:name'}>
                <TextFieldBold source="name"/>
            </ReferenceField>,
        /*year: <TextField source="year" label={'Year'} textAlign={"right"}/>,*/
    }
    let dt = {
        salary: 'salaries',
        other: 'others',
        deprecation: 'deprecations',
        office: 'offices',
    }
    if (prop_types_belongs) {
        prop_types_belongs.forEach(it => {
            //console.log(it.property_details.name,'====property==========>', `property_id_${it.property_id}`)
            switch (it.property_details.style) {
                case 'int':
                    columns[`property_id_${it.property_id}`] =
                        <TextField source={`expense_properties.id_${it.property_id}`} label={it.property_details.name}/>
                    break;
                case 'float':
                    columns[`property_id_${it.property_id}`] =
                        <FloatNumber source={`expense_properties.id_${it.property_id}`} label={it.property_details.name}
                                     textAlign={"right"}/>
                    break;
                case 'date':
                    columns[`property_id_${it.property_id}`] =
                        <DateFieldComp source={`expense_properties.id_${it.property_id}`}
                                       label={it.property_details.name}
                                       textAlign={"center"} cellClassName={classes.date_st} showTime={false}
                                       locale={'us'}/>
                    break;
                default:
                    columns[`property_id_${it.property_id}`] =
                        <TextField source={`expense_properties.id_${it.property_id}`} label={it.property_details.name}/>
                    break;
            }
        })
    }
    if (exp_compositions) {
        exp_compositions.forEach(it => {
            columns[it.name] = <SupportTable label={it.name}
                                             tbl={dt[it.expenses_amount_type]}
                                             classes={classes}
                                             cellClassName={classes.rowCellBottom}
                                             headerClassName={classes.rowCellCenter}/>
        })
    }

    columns['created_at'] = <DateFieldComp source="created_at" label={'Created'} textAlign={'center'} showTime={true}
                                           cellClassName={classes.date_st}/>
    columns.updated_at = <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'} showTime={true}
                                        cellClassName={classes.date_st}/>

    return columns;
};

const PostShow = (props) => {
    //const classes = useMyStyles();
    const {record, expenses_type_id} = props;
    const {id, expense_properties, totals} = record || {};
    const {exp_compositions, prop_types_belongs} = getConfigFromStorage(expenses_type_id);
    return (
        <SimpleShowLayout>
            <Grid container style={{maxWidth: 1200, marginTop: 0, marginBottom: 16}} spacing={2}>
                {prop_types_belongs.map(it => {
                    return (
                        <Grid item xs={3} style={{marginBottom: 0, display: "grid"}}>
                            {/*<CompProp it={it}/>*/}
                            <b style={{color: '#666'}}>{it.property_details.name}</b>
                            <span style={{color: '#aaa'}}>{expense_properties[`id_${it.property_id}`]}</span>
                        </Grid>
                    )

                })
                }
            </Grid>
            <ExpCompositions id={id} exp_compositions={exp_compositions}/>
            {/*<Grid container style={{width: '100%'}}>
                    <Grid item>
                        <h4 style={{margin: '4px 0'}}>Changing / Refund</h4>
                        <SalariesTable {...props}  expense_id={id}/>
                    </Grid>
            </Grid>*/}
            {totals &&
                <Grid
                    container
                    style={{maxWidth: 1200, marginTop: 0, marginBottom: 16, backgroundColor: 'aliceblue'}}
                    spacing={2}
                    justify={"space-around"}
                >
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Contribution 3rs</b>
                        <span style={{color: '#aaa'}}>{formatSpacerNumber(totals.contribution_3rs)}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Grand Total Contributions</b>
                        <span style={{color: '#aaa'}}>{formatSpacerNumber(totals.grand_total_contributions)}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Overall Impact</b>
                        <span style={{color: '#aaa'}}>{totals.overall_impact}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Reach Classification</b>
                        <span style={{color: '#aaa'}}>{totals.reach_classification}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Reach Score</b>
                        <span style={{color: '#aaa'}}>{totals.reach_score}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Relevance Score</b>
                        <span style={{color: '#aaa'}}>{totals.relevance_score}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Total Score</b>
                        <span style={{color: '#aaa'}}>{totals.total_score}</span>
                    </Grid>
                </Grid>
            }
        </SimpleShowLayout>
    );
}

export const ExpTablesList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource, expenses_type_id} = props;
    let is_asside = false;

    const [total, setTotal] = React.useState(null);
    const [year, setYear] = React.useState(
        localStorage.getItem('swiss_exp_year')
            ? localStorage.getItem('swiss_exp_year')
            : parseInt(moment(new Date()).format('YYYY'))
    );

    const getTotal = async (year) => {
        const url = apiPathConfig.getApiUrl()
        let report_url = `api/additional/total?year=${year}&expenses_type_id=${expenses_type_id}`;
        try {
            const response = await httpClient(`${url}${report_url}`);
            if (response.status >= 200 && response.status <= 300) {
                console.log('total=======>', response.json.results);
                setTotal(response.json.results)
            }
        } catch (err) {
            //console.log('err=======>', err);
        } finally {
            //console.log('finally=======>', 'finally');
            //refresh();
        }
    };
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    useEffect(() => {
        if (year) {
            getTotal(year);
            /*setTimeout(function () {
                setYear(year);
            }, 900);*/
        }

    }, [year]) // eslint-disable-line

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, expenses_type_id),
        omit: ["nb_views"],
    });

    //console.log('exp_compositions====1==========>', exp_compositions)
    //console.log('prop_types_belongs==1===========>', prop_types_belongs)
    const ListYear = getListYear();
    const {total: total1 = null} = total || {};
    console.log(year, '==========>', ListYear)
    return (
        <>
            <table style={{marginBottom: -28}}>
                <tr>
                    {/*<td style={{width: 200}}><h3>{nameTable}</h3></td>*/}
                    <td><h3>Total: {formatSpacerNumber(total1)}</h3></td>
                </tr>
            </table>

            <ListComponentAdmin
                {...props}
                //component={'div'}
                title={' '}

                actions={<TableActions
                    //showAsside={false}
                    create={true}
                    preference={`${resource}list.columns`}
                    columns={ColumnsForList(resource, locale, classes, expenses_type_id)}
                    permissions={permissions}
                    //asideFilter={}
                    // filter={false}
                    //exportCsv={true}
                    //exporterFile={exporterPassenger}
                />}
                filters={TopFilters(resource, locale)}
                filterDefaultValues={{expenses_type_id: expenses_type_id, year: year}}

                sort={{field: 'id', order: 'DESC'}}
                aside={is_asside && <Aside/>}
            >
                {isSmall
                    ?
                    <SimpleList
                        rowClick={false}
                        primaryText={<TextField source="name"/>}
                        secondaryText={null}
                        tertiaryText={
                            <Box
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '16px',
                                    display: 'grid',
                                }}
                            >
                                {columns}
                            </Box>
                        }
                        linkType={false}//{record => record.canEdit ? "edit" : "show"}
                        rowStyle={postRowStyle}
                    />
                    :
                    /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                    <Fragment>
                        {/*<TabContext value={0}>
                        <TabList
                                    onChange={(event, value) => {
                                localStorage.setItem('swiss_exp_year', value);
                                setYear(ListYear[value].id)
                            }}
                                    aria-label="lab API tabs example"
                                >
                             {ListYear.map((it, index) => (
                                <Tab key={index} label={it.name} value={index} style={{maxWidthwidth: 60}}/>
                            ))}
                            </TabList>
                        </TabContext>*/}

                        <Tabs
                            fullWidth
                            //centered
                            value={year}
                            indicatorColor="primary"
                            onChange={(event, value) => {
                                localStorage.setItem('swiss_exp_year', value);
                                setYear(value)
                            }}
                        >
                            {ListYear.map(choice => (
                                <Tab
                                    key={choice.id}
                                    label={choice.name}
                                    value={choice.id}
                                    style={{minWidth: 32, backgroundColor: choice.id === year && '#f5f7fa'}}
                                />
                            ))}
                        </Tabs>
                        <DatagridComponentAdmin
                            {...props}
                            rowClick="edit"
                            rowStyle={postRowStyle}
                            expand={<PostShow expenses_type_id={expenses_type_id}/>}
                            /*isRowExpandable={row => (
                                //|| (row.travel_credits && row.travel_credits.length > 0)
                                (row.transactions && row.transactions.length > 0)
                            )}*/
                        >
                            {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                            {columns}
                        </DatagridComponentAdmin>
                    </Fragment>
                }
            </ListComponentAdmin>
        </>
    )
}