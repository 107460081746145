import {apiPathConfig} from "./config";

const authProvider = {
    // called when the user attempts to log in
    login: ({username, password}) => {
        // localStorage.setItem('username', "username");
        // return;
        const request = new Request(`${apiPathConfig.getApiUrl()}rest-auth/login/`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(key => {
                localStorage.setItem('auth', JSON.stringify(key));
                localStorage.setItem('username', username);
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        if (!apiPathConfig.getAuth()) return Promise.resolve();
        return localStorage.getItem('username')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    // getPermissions: () => Promise.resolve(),
    getPermissions: () =>
        Promise.resolve({
            permissions: [
                {action: ["read", "write"], resource: "*"},
            ],
            roles: ["reader"],
        }),
    getRoles: () =>
        Promise.resolve({
            admin: [{action: "*", resource: "*"}],
            reader: [{action: "*", resource: "*"}],
        }),
};

export default authProvider;