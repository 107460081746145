import * as React from 'react';
/*import {
    useNotify,
    useRedirect, useRefresh,
} from 'react-admin';*/

import EditComponentAdmin from "../_common/EditComponentAdmin";
import ExpTablesEditCreate from "../related_tables/ExpTablesEditCreate";
import {getConfigFromStorage} from "../_common/CompReact";

export const AllExpensesEdit = props => {
    /*const {location} = props;
    const {search, pathname} = location;
    let customerId = '';
    if(search){
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
    }
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = (data) => {
        notify(`Saved data`);
        redirect(pathname)
        refresh();
    };*/
    const {resource} = props;
    const {exp_types} = getConfigFromStorage(resource.replace(/\D/g,''));
    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            //onSuccess={() => props.history.goBack()}
            //onSuccess={() => customerId ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={onSuccess}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <ExpTablesEditCreate expenses_type_id={exp_types.id} nameTable={exp_types.name}/>
        </EditComponentAdmin>
    )
};