//import * as React from "react";

export const config = {
    //keycloakDomain: process.env.REACT_APP_AUTH_DOMAIN,
    apiUrl: process.env.REACT_APP_API_URL,
    bookmanID: process.env.REACT_APP_BOOKMAN_ID,
    cognito: true,//false,
    auth: true,//false  // false is allowed only when cognito: false
}

export const localesConfig = {
    defaultLocale: 'en',
    locales: ['en', 'ru'],
    languages: [
        {locale: "en", name: "English 🇬🇧"},
        {locale: "ru", name: "Русский"},
    ]
}
export const apiPathConfig = {
    getUrl: (name, type = '') => {
        // const params = apiPathConfig[name]
        switch (name) {
            case 'expenses': return config.apiUrl + 'api/admin/expenses';

            case 'all_expenses': return config.apiUrl + 'api/admin/expenses';

            /*case 'direct_support': return config.apiUrl + 'api/admin/expenses';
            case 'infrastructure': return config.apiUrl + 'api/admin/expenses';
            case 'animal_facilities': return config.apiUrl + 'api/admin/expenses';
            case 'programs': return config.apiUrl + 'api/admin/expenses';
            case 'educations': return config.apiUrl + 'api/admin/expenses';*/

            case 'salaries': return config.apiUrl + 'api/admin/salaries';
            case 'deprecations': return config.apiUrl + 'api/admin/deprecations';
            case 'offices': return config.apiUrl + 'api/admin/offices';
            case 'others': return config.apiUrl + 'api/admin/others';

            case 'positions': return config.apiUrl + 'api/admin/positions';
            case 'companies': return config.apiUrl + 'api/admin/companies';
            case 'groups': return config.apiUrl + 'api/admin/groups';
            case 'exp_types': return config.apiUrl + 'api/admin/expenses_types';
            case 'exp_properties': return config.apiUrl + 'api/admin/expenses_properties';
            case 'exp_compositions': return config.apiUrl + 'api/admin/expenses_compositions';
            case 'prop_values': return config.apiUrl + 'api/admin/property_values';
            case 'prop_types_belongs': return config.apiUrl + 'api/admin/properties_types_belongs';

            default: return config.apiUrl + 'api/admin/expenses';
        }
    },

    getApiUrl: () => config.apiUrl,
    getAuth: () => config.auth,
    getCognito: () => config.cognito,
    // getPublicApiUrl: () => {
    //     const parts = config.apiUrl.split('//')
    //     const part1 = parts[1].replace('/api', '/public')
    //     return [parts[0], part1].join('//')
    // },
}

export default config
