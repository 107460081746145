import React from 'react';
import '../index.css';
import {ExpTablesList} from "../related_tables/ExpTablesList";
import {getConfigFromStorage} from "../_common/CompReact";

export const AllExpensesList = ({permissions, ...props}) => {

    const {resource} = props;
    const {exp_types} = getConfigFromStorage(resource.replace(/\D/g,''));
    return (
        <ExpTablesList
            {...props}
            nameTable={exp_types.name}
            expenses_type_id={exp_types.id}
            permissions={permissions}
        />
    )

}