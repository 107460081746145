import * as React from 'react';
import {
    TextField, ReferenceManyField,
    NumberInput, TextInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {DateFieldComp, DateInputComp, FloatNumber, NumberInputFloatComp} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const {expense_id} = props;
    const classes = useMyStyles();
    /*const {getValues} = useFormContext();
    console.log(booking_id, '=====OK======>', getValues("id"))*/
    return (
        <RowForm  {...props} initialValues={{expense_id: expense_id}}>
            {/*<DateInputComp source={"hc_travel_date"} label={"Travel date"} validate={validateReq}/>*/}
            <DateInputComp source={"date"} label={"Date"} validate={validateReq}/>
            <TextInput source="description" label={'Description'} validate={validateReq}/>
            <NumberInput source="deprecation_years" label={'Deprecation Years'} validate={validateReq}/>
            <NumberInputFloatComp source="total_cost" label={'Total Cost'} validate={validateReq} className={classes.price_st_input}/>
            {/*<SelectInput source="flight_status" choices={hidden_city_flight_status} validate={validateReq}/>*/}
            <NumberInputFloatComp source="contribution" label={'Contribution'} validate={validateReq} className={classes.price_st_input}/>
        </RowForm>
    )
};

const DeprecationTable = props => {
    const {expense_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    return (
        <div style={{width: "max-content", minWidth: 800, marginBottom: 16}}>
            <ReferenceManyField
                fullWidth
                label=" "
                reference="deprecations"
                target="expense_id"
            >
                <EditableDatagrid
                    classes={{headerCell: classes.headerCellVerticalCenter, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={true}
                    /*onChange={(data) => {
                        console.log('===fdsBookingForm==========>')
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    }}*/
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} expense_id={expense_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} expense_id={expense_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {/*<DateFieldComp source="hc_travel_date" label={'Travel Date'} textAlign={"center"}
                                   cellClassName={classes.date_st} showTime={false} locale={'us'}/>*/}
                    <DateFieldComp source="date" label={'Date'} textAlign={"center"}
                                   cellClassName={classes.date_st} showTime={false} locale={'us'}/>
                    <TextField source="description" label={'Description'}/>
                    <TextField source="deprecation_years" label={'Deprecation Years'} textAlign={"right"}/>
                    <FloatNumber source={`total_cost`} label={'Total Cost'}
                                 textAlign={"right"}/>
                    <FloatNumber source={`contribution`} label={'Contribution'}
                                 textAlign={"right"}/>
                </EditableDatagrid>
            </ReferenceManyField>
        </div>
    )
}
export default DeprecationTable;
