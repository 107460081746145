import * as React from 'react';
// import {Admin, createMuiTheme} from 'react-admin';
import {
    Admin,
    // ListGuesser,
    // EditGuesser
} from 'react-admin';
import dataProvider from "./utils/dataProvider";
// import dataProviderFake from "./utils/dataProviderFake";
// import simpleRestProvider from 'ra-data-simple-rest';
import i18nProvider from './utils/i18nProvider';
import MyLayout from "./layout/MyLayout";
import Dashboard from "./Dashboard";

import {Resource} from "@react-admin/ra-rbac";

/*-------COGNITO------------*/
// import {AuthProvider} from 'ra-cognito';
import {buildAuthProvider} from "react-admin-amplify";
import Amplify from 'aws-amplify';
import authProvider from './utils/authProvider';
// // import PermIdentity from "@material-ui/core/SvgIcon/SvgIcon";
import {apiPathConfig} from "./utils/config";
import positions from "./positions";
import companies from "./companies";
import groups from "./groups";
import exp_properties from "./exp_properties";
import exp_compositions from "./exp_compositions";
import prop_values from "./prop_values";
import prop_types_belongs from "./prop_types_belongs";

import {Hub} from 'aws-amplify';
import {clearLocalStorage, setConfigToStorage} from "./_common/CompReact";
import all_expenses from "./all_expenses";
import exp_types from "./exp_types";

Hub.listen('auth', (data) => {
    //https://docs.amplify.aws/guides/authentication/listening-for-auth-events/q/platform/js/
    switch (data.payload.event) {
        case 'signIn':
            setConfigToStorage();
            console.log('user signed in');
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            clearLocalStorage(false);
            console.log('user signed out');
            break;
        case 'signIn_failure':
            console.log('user sign in failed');
            break;
        case 'configured':
            console.log('the Auth module is configured');
            break;
        default:
            break;
    }
});

if (apiPathConfig.getCognito) {
    const amplifyConfig = {
        Auth: {
            //storage: ICognitoStorage,
            //cookieStorage: ICookieStorageData,
            /*cookieStorage: {
                domain: "localhost",
                path: "/",
                expires: 365,
                secure: true
            },*/
            //?? identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab', //REQUIRED - Amazon Cognito Identity Pool ID
            region: process.env.REACT_APP_COGNITO_REGION, //'us-east-1',
            userPoolId: process.env.REACT_APP_COGNITO_POOL_ID, //'us-east-1_naqZRfsYi',
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, //'2t04thphs1r35np5gf367v4hdd',
            /*oauth: {
                   //domain: 'https://cc-react-admin.auth.eu-central-1.amazoncognito.com',
                   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                   redirectSignIn: 'http://localhost:3000/',
                   redirectSignOut: 'http://localhost:3000/',
                   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
               }*/
        },
    };

    Amplify.configure(amplifyConfig);
}

/*const showDefault = {
    list: ListGuesser,
    edit: EditGuesser,
}*/
// if (apiPathConfig.getAuth()) token = 'Token ' + JSON.parse(localStorage.getItem('auth')).key;
/*
localStorage.setItem('preferences', JSON.stringify(preferences));
*/

const App = () => {
    let swiss_exp_types = JSON.parse(localStorage.getItem("swiss_exp_types"));
    return (
        <Admin
            authProvider={apiPathConfig.getCognito() ?
                buildAuthProvider({authGroups: ["Admins", "admin"]}) :
                authProvider}
            // authProvider={authProvider}
            //authProvider={buildAuthProvider({authGroups: ["Admins", "admin"]})}
            // dataProvider={dataProviderFake}
            dataProvider={dataProvider}
            // dataProvider={simpleRestProvider(process.env.REACT_APP_API_URL)}
            locale="en"
            //theme={myTheme}
            i18nProvider={i18nProvider}
            layout={MyLayout}
            title="swiss3rcc"
            dashboard={Dashboard}
            //loginPage={Login}
            disableTelemetry
        >
            {swiss_exp_types && swiss_exp_types.map((item, index) => {
                return (
                    <Resource key={index} name={`expenses_${item.id}`} {...all_expenses} options={{label: item.name}}/>
                )
            })
            }

            <Resource name={'expenses'} options={{label: 'expenses'}}/>
            {/*<Resource name={'exp_direct_supports'} {...exp_direct_supports} options={{label: 'Direct Supports'}}/>
            <Resource name={'exp_infrastructure'} {...exp_infrastructure} options={{label: 'Infrastructure'}}/>
            <Resource name={'exp_animal_facilities'} {...exp_animal_facilities} options={{label: 'Animal Facilities'}}/>
            <Resource name={'exp_programs'} {...exp_programs} options={{label: 'Programs'}}/>
            <Resource name={'exp_educations'} {...exp_educations} options={{label: 'Educations'}}/>*/}


            <Resource name={'salaries'} options={{label: 'Salaries'}}/>
            <Resource name={'deprecations'} options={{label: 'deprecations'}}/>
            <Resource name={'offices'} options={{label: 'offices'}}/>
            <Resource name={'others'} options={{label: 'others'}}/>

            <Resource name={'positions'} {...positions} options={{label: 'Positions'}}/>
            <Resource name={'companies'} {...companies} options={{label: 'Companies'}}/>
            <Resource name={'groups'} {...groups} options={{label: 'Groups'}}/>
            <Resource name={'exp_types'} {...exp_types} options={{label: 'Menu'}}/>
            <Resource name={'exp_properties'} {...exp_properties} options={{label: 'Properties'}}/>
            <Resource name={'exp_compositions'} {...exp_compositions} options={{label: 'Compositions'}}/>
            <Resource name={'prop_values'} {...prop_values} options={{label: 'Values'}}/>
            <Resource name={'prop_types_belongs'} {...prop_types_belongs} options={{label: 'Types Belongs'}}/>


        </Admin>
    )
};
/*some changes*/

export default App;
