//import * as React from 'react';
import {Card as MuiCard} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const CardComp = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '11em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            order: -1,
            width: '0',
            marginRight: '1em',
            position: 'absolute',
            marginTop: '3em',
        },
    },
}))(MuiCard);

export default CardComp;
