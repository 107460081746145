import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {ExpProperties} from "./ExpProperties";
// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: ExpProperties,
    // create: BaseListIdNameCreate,
    // edit: BaseListIdNameEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;