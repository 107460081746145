/*
import {Layout} from 'react-admin';
import MyAppBar from "./MyAppBar";
import {MyMenu} from "./MyMenu";

const MyLayout = props => {

    return (<Layout
        {...props}
        appBar={MyAppBar}
        menu={MyMenu}
    />)
};

export default MyLayout
*/
import {Layout} from 'react-admin';
import MyAppBar from "./MyAppBar";
import {MyMenu} from "./MyMenu";
import {AppLocationContext, Breadcrumb, ResourceBreadcrumbItems} from "@react-admin/ra-navigation";

const MyLayout = ({children, ...rest}) => {
    return (
        <AppLocationContext>
            <Layout
                {...rest}
                appBar={MyAppBar}
                menu={MyMenu}
            >
                <Breadcrumb hasDashboard>
                    <ResourceBreadcrumbItems/>
                </Breadcrumb>
                {children}
            </Layout>
        </AppLocationContext>
    )
};

export default MyLayout

