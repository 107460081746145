import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
// import hebrewMessages from 'ra-language-hebrew';
// import ArabicMessages from 'ra-language-arabic';
import russianMessages from 'ra-language-russian';
import {localesConfig} from "./config";
import {
    resolveBrowserLocale,
} from 'react-admin';

import {transRu} from "../_common/trans";
import {transEn} from "../_common/trans";

const messages = {
    ru: { ...russianMessages, ...transRu },//hebrewMessages
    en: { ...englishMessages, ...transEn}//transEn,//englishMessages,
    // ru: russianMessages,
    // ar: englishMessages,
    // ar: ArabicMessages,
};
//console.log('============>', messages.he)
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale],
    localesConfig.defaultLocale, // Default locale
    // locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale('en', { fullLocale: true }), // 'en' => Default locale when browser locale can't be resolved, { fullLocale: true } => Return full locale
    {allowMissing: true}, //Отключает warning от внешних источников

);

export default i18nProvider
