import * as React from 'react';

import {
    DeleteButton,
    SaveButton,
    Toolbar, useNotify, useRedirect,
} from 'react-admin';
import {canAccess, usePermissions} from "@react-admin/ra-rbac";
import BackButton, {Reload} from "./BackButton";

const PostEditDeleteActions = (
    {
        reload = false,
        saveAndClose = '',
        action = 'all',
        goBack = false,
        ...props
    }) => {
    const {resource} = props;
    const {loading, permissions} = usePermissions();

    const redirect = useRedirect()
    const notify = useNotify();

    if (loading) return null;
    // const canDelete = canAccess({permissions, resource: 'tickets.delete', action: 'delete'})
    const canDelete = canAccess({permissions, resource: `${resource}`, action: 'delete'})
    //console.log('resource=====>', canDelete, resource, props);
    if (action === 'withoutButtons') return null;
    return (
        <Toolbar {...props} style={{display: "flex", justifyContent: "space-between"}}>
            {resource === 'users'
                ?
                <SaveButton
                    submitOnEnter={true}
                    transform={data => {
                        delete data.password
                        return ({...data})
                    }}/>
                :
                <SaveButton
                    submitOnEnter={true}
                    //onClick={() => alert('Saving...')}
                />
            }
            {/*<SaveButton/>*/}
            {saveAndClose &&
                    <SaveButton
                    label={'Save & Close'}
                    onSuccess={data => {
                        notify(`Saved data`);
                        redirect(saveAndClose)
                    }}
                    submitOnEnter={true}
                    //onClick={() => alert('Saving...')}
                />
            }
            {reload &&
                <Reload/>
            }
            {goBack && <BackButton/>}
            {canDelete && action !== 'create' && <DeleteButton/>}
        </Toolbar>
    );
}

export default PostEditDeleteActions;
