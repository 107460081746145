import {makeStyles} from "@material-ui/core";
//https://stackoverflow.com/questions/56720432/how-to-style-the-header-of-react-admin-datagrid
const useMyStyles = makeStyles({
    overflowWrap: {overflowWrap: "anywhere", minWidth: "88px"},
    minWidthDate: {minWidth: "200px"},
    truncateText: {
        overflow: 'hidden',
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
        lineHeight: "1.3em",
        height: "3.9em"
    },
    headerButtonEdiWidth: {
        maxWidth: '48px',
        padding: "6px 12px",
    },
    headerShortWidthBooking: {
        minWidth: 50,
        maxWidth: 50,
        padding: "6px 12px",
    },
    headerShortWidth: {
        //maxWidth: '28px',
        padding: "6px 12px",
    },
    headerImageWidth: {
        width: '64px',
        padding: "6px 12px",
        textAlign: "center"
    },
    headerCell: {
         backgroundColor: '#f5f7fa',
        // color: 'white',
        fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '8px 8px 8px 8px',
        verticalAlign: "bottom",
        border: '1px solid #eee',
    },
    headerCellVerticalCenter: {
         backgroundColor: '#f5f7fa',
        // color: 'white',
        fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '8px 8px 8px 8px',
        verticalAlign: "middle",
        border: '1px solid #eee',
    },
    headerCellPostShow: {
        // backgroundColor: '#def2ff',
        // color: 'white',
        //fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '6px 8px 6px 8px',
        verticalAlign: "bottom",
        border: '1px solid #eee',
    },
    headerCellBorder: {
        //backgroundColor: '#def2ff',
        // color: 'white',
        fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '6px 8px 6px 8px',
        verticalAlign: "bottom",
        border: '1px solid #eee',
    },
    // rowCell: {padding: '6px 16px 6px 16px'},
    rowCell: {padding: '6px 8px 6px 8px', border: '1px solid #eee'},
    rowCellBorder: {padding: '6px 8px 6px 8px', border: '1px solid #eee'},

    rowCellLeft: {textAlign: "left"},
    rowCellRight: {textAlign: "right"},
    rowCellCenter: {textAlign: "center"},
    rowCellTop: {verticalAlign: "top"},
    rowCellBottom: {verticalAlign: "bottom"},

    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fefefe',
        },
        '&: td': {textAlign: "center"},
        /*'&:last-child td, &:last-child th': {
          border: 0,
        },*/
    },

    /*=======BOOKING=====================*/
    /*SimpleFormIterator: {???
        display: "inline-flex",
        '&: li': {
            color: "red",
            '&: section': {display: "flex"},
        },

    },*/

    alarmField: {color: "red"},
    booking_title: {
        borderTop: '1px solid darkgrey',
        width: '100%',
        paddingTop: '8px'
    },
    cost_show_field: {
        backgroundColor: 'aliceblue',
        padding: '4px 16px',
        borderRadius: 4,
    },
    cost_show_field_calc: {
        marginTop: -22,
        backgroundColor: 'aliceblue',
        padding: '4px 16px',
        borderRadius: 4,
    },
    /*============================*/
    colorCost: {backgroundColor: "antiquewhite", textAlign: "right"},
    borderLeftRed: {borderLeft: "4px solid red"},
    razd: {backgroundColor: "red", width: '4px', minWidth: '4px', padding: 0},

    textRight: {textAlign: "right"},
    textCenter: {textAlign: "center"},

    date_st: {
        minWidth: 90,
        maxWidth: 90,
        textAlign: "center",
    },
    price_st: {
        // width: 120,
        minWidth: 80,
        maxWidth: 80,
        textAlign: "right",
    },
    months_day: {
        // width: 120,
        minWidth: 30,
        maxWidth: 30,
        textAlign: "right",
    },
    months_day_input: {
        // width: 120,
        minWidth: 80,
        maxWidth: 80,
    },
    price_st_input: {
        // width: 120,
        minWidth: 160,
        maxWidth: 160,
    },
    description: {minWidth: 120, maxWidth: 120, textAlign: "left"},
    positionName: {minWidth: 90, maxWidth: 90, textAlign: "left"},



    customer_st: {
        // width: '100px',
        minWidth: '140px',
        maxWidth: '140px',
        textAlign: "left",
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        fontSize: '12px',
    },
    invoice: {
        minWidth: 160,
        maxWidth: 160,
        textAlign: "center",
    },
    supportOffices_st: {
        minWidth: 128,
    },
    airline_st: {
        minWidth: 90,
        maxWidth: 90,
        fontSize: '11px',
    },
    costHead_st: {
        // width: 120,
        minWidth: 80,
        maxWidth: 80,
        backgroundColor: "antiquewhite",
        textAlign: "center",
    },
    cost_st: {
        // width: 120,
        minWidth: 80,
        maxWidth: 80,
        backgroundColor: "antiquewhite",
        textAlign: "right",
    },
    sales_price_st: {
        // minWidth: '86px'
        textAlign: "right",
    },
    quantity_st: {
        minWidth: 50,
        maxWidth: 50,
        // minWidth: '64px'
        textAlign: "right",
    },
    taxes_st: {
        // minWidth: '64px'
        textAlign: "right",
    },
    other_fee_st: {
        // minWidth: '64px'
        textAlign: "right",
    },
    total_st: {
        // minWidth: '74px'
        //backgroundColor: "antiquewhite",
        textAlign: "right",
    },

    currency_st: {
        // minWidth: '64px'
        textAlign: "center",
    },
    passenger_title_st: {
        textAlign: "left",
    },
    passenger_st: {
        minWidth: 100,
        maxWidth: 100,
        textAlign: "left",
    },
    pnr_st: {
        minWidth: '148px', /*'259px',*/
        maxWidth: '148px',
        // maxWidth: '259px',
        textAlign: "left",
        /*whiteSpace: 'nowrap',*/ /* Запрещаем перенос строк */
        /*overflow: 'hidden',*/ /* Обрезаем все, что не помещается в область */
        /*textOverflow: 'ellipsis',*/
    },
    ticket_number_st: {
        minWidth: '142px',
        maxWidth: '142px',
        // maxWidth: '142px',
        textAlign: "left",
    },
    route_st: {
        minWidth: '123px',
        maxWidth: '123px',
        // maxWidth: '123px',
        textAlign: "left",
    },
    ticket_type_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "center",
    },
    flight_number_st: {
        minWidth: '121px',
        maxWidth: '121px',
        textAlign: "left",
    },
    ticket_class_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    miles_only_st: {
        // minWidth: 'px',
        // maxWidth: 'px',
        textAlign: "center",
    },
    notes_st: {
        minWidth: '64px',
        maxWidth: '64px',
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        textAlign: "left",
    },
    supplier: {
        minWidth: '90px',
        maxWidth: '90px',
        // whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        // overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        // textOverflow: 'ellipsis',
        textAlign: "left",
    },
    booking_ref: {
        minWidth: '140px',
        maxWidth: '140px',
        // whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        // overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        // textOverflow: 'ellipsis',
        textAlign: "left",
    },
    program_title_st: {
        minWidth: '97px',
        maxWidth: '97px',
        textAlign: "left",
    },
    account_name_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    account_number_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    credit_card: {
        minWidth: '50px',
        maxWidth: '50px',
        textAlign: "left",
        overflowWrap: "anywhere",
    },
    username_st: {
        minWidth: '90px',
        maxWidth: '90px',
        textAlign: "left",
        overflowWrap: "anywhere",
    },
    password_st: {
        minWidth: '80px',
        maxWidth: '80px',
        textAlign: "left",
    },
    dob_st: {
        minWidth: '64px',
        maxWidth: '64px',
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        textAlign: "left",
    },
    phone_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    cc_for_booking_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    cvv_cvc_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    exp_st: {
        minWidth: '69px',
        // maxWidth: '69px',
        textAlign: "left",
    },
    email_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    account_email_password_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    supplier_notes_st: {
        minWidth: '64px',
        maxWidth: '64px',
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        textAlign: "left",
    },
    paid_st: {
        textAlign: "center",
    },
});

export default useMyStyles;