// in src/Menu.js
import * as React from 'react';
import {useState} from 'react';
import {
    //DashboardMenuItem,
    useTranslate,
    // usePermissions,
} from 'react-admin';
import {useSelector} from 'react-redux';
// import {Divider} from '@material-ui/core';
import SubMenu from "./SubMenu";
import {MenuItemLink} from 'react-admin';

import BusinessIcon from '@material-ui/icons/Business';
// import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
// import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
//-import AirplaneTicketIcon from '@material-ui/icons/AirplaneTicket';
//-import TerminalIcon from '@material-ui/icons/Terminal';
//-import FeedIcon from '@material-ui/icons/Feed';
//-import ArticleIcon from '@material-ui/icons/Article';

//import DashboardIcon from '@material-ui/icons/Dashboard';
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// import LocalAirportIcon from '@material-ui/icons/LocalAirport';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
//import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
//import AirplayIcon from '@material-ui/icons/Airplay';
//import CreditCardIcon from '@material-ui/icons/CreditCard';
import {Divider} from "@material-ui/core";
import {setConfigToStorage} from "../_common/CompReact";

export const MyMenu = (props) => {
    const translate = useTranslate();
    const {dense} = props || {dense: false}
    //const resources = useSelector(getResources);

    const [state, setState] = useState({
        menuTickets: false,
        menuOldTable: false,
    });

    setConfigToStorage();

    useSelector((state) => state.theme); // force rerender on theme change
    // const classes = useStyles();

    /*const {loading, permissions} = usePermissions();
    if (loading) return null;*/
    const handleToggle = (menu) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    let exp_types = JSON.parse(localStorage.getItem("swiss_exp_types"));

    return (
        <div>
            {/*<DashboardMenuItem/>*/}
            {/*<MenuItemLink
                to={`/`} // by default react-admin renders Dashboard on this route
                primaryText="Dashboard"
                leftIcon={<DashboardIcon/>}
                //onClick={onMenuClick}
                sidebarIsOpen={true}
                dense={dense}
            />*/}
            <Divider/>
            {
                exp_types && exp_types.map((item, index) => {
                        return (
                            <MenuItemLink
                                to={{
                                    //pathname: `/${item.expenses_type}`,
                                    pathname: `/expenses_${item.id}`,
                                    state: {_scrollToTop: true},
                                }}
                                primaryText={translate(`${item.name}`)}
                                leftIcon={<BusinessIcon/>}
                                dense={dense}
                            />
                        )
                })
            }
            {/*<SubMenu
                handleToggle={() => handleToggle('menuTickets')}
                isOpen={state.menuTickets}
                name={translate(`${'CONFIG TABLE'}`)}
                //icon={<BusinessIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to={{
                        pathname: '/exp_direct_supports',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Direct Supports'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/exp_infrastructure',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Infrastructure'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/exp_animal_facilities',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Animal Facilities'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/exp_programs',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Programs'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/exp_educations',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Educations'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            </SubMenu>*/}
            <SubMenu
                handleToggle={() => handleToggle('menuTickets')}
                isOpen={state.menuTickets}
                name={translate(`${'CONFIG TABLE'}`)}
                //icon={<BusinessIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to={{
                        pathname: '/positions',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Positions'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />

                <MenuItemLink
                    to={{
                        pathname: '/companies',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Companies'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />

                <MenuItemLink
                    to={{
                        pathname: '/groups',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Groups'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/exp_types',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Menu'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />

                <MenuItemLink
                    to={{
                        pathname: '/exp_properties',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Properties'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/exp_compositions',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Compositions'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/prop_values',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Values'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/prop_types_belongs',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Types Belongs'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />

            </SubMenu>
        </div>
    )
};