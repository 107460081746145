import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {Companies} from "./Companies";
// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: Companies,
    // create: BaseListIdNameCreate,
    // edit: BaseListIdNameEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;