import * as React from 'react';
import {
    TextInput,
    SimpleForm,
    NumberInput,
    ReferenceInput,
    AutocompleteInput, FormDataConsumer,
    SelectInput,
} from 'react-admin';

import {Box, Grid, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, formatSpacerNumber, getConfigFromStorage, NumberInputFloatComp} from "../_common/CompReact";
//import useMyStyles from "../utils/useMyStyles";
import {validateReq} from "../utils/validateTable";
import SalariesTable from "../related_tables/SalariesTable";
import DeprecationTable from "../related_tables/DeprecationTable";
import OfficeTable from "../related_tables/OfficeTable";
import OtherTable from "../related_tables/OtherTable";
import {getListYear} from "../utils/choices";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
//import {useSelector} from "react-redux";
/*
import {useEffect} from "react";
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
*/

const CompProp = (props) => {
    const {it} = props;
    switch (it.property_details.style) {
        case 'int':
            return (
                <NumberInput
                    source={`expense_properties.id_${it.property_id}`}
                    label={it.property_details.name}
                    validate={validateReq} fullWidth/>
            )
        case 'float':
            return (
                <NumberInputFloatComp
                    source={`expense_properties.id_${it.property_id}`}
                    label={it.property_details.name}
                    validate={validateReq} fullWidth/>
            )
        case 'date':
            return (
                <TextInput
                    source={`expense_properties.id_${it.property_id}`}
                    label={it.property_details.name}
                    validate={validateReq} fullWidth/>
            )
        default:
            return (
                <TextInput
                    source={`expense_properties.id_${it.property_id}`}
                    label={it.property_details.name}
                    validate={validateReq} fullWidth/>
            )
    }
}
const PropTypesBelongs = (props) => {
    const {prop_types_belongs} = props;
    return (
        <Grid container style={{width: '100%', marginTop: 8}} spacing={2}>
            {prop_types_belongs.map(it => {
                return (
                    <Grid item xs={4} xl={3} style={{marginBottom: -16}}>
                        <CompProp it={it}/>
                    </Grid>
                )

            })
            }
        </Grid>
    )
}
export const ExpCompositions = (props) => {
    const {id, exp_compositions} = props;
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {
                        exp_compositions.map((it, index) => {
                            return (
                                <Tab label={it.name} value={index}/>
                            )
                        })
                    }
                </TabList>
            </Box>
            {
                exp_compositions.map((it, index) => {
                    switch (it.expenses_amount_type) {
                        case 'salary':
                            return (
                                <TabPanel value={index} style={{padding: '16px 0'}}>
                                    <SalariesTable {...props}
                                                   expense_id={id}/>
                                </TabPanel>
                            )
                        case 'deprecation':
                            return (
                                <TabPanel value={index} style={{padding: '16px 0'}}>
                                    <DeprecationTable {...props}
                                                      expense_id={id}/>
                                </TabPanel>
                            )
                        case 'office':
                            return (
                                <TabPanel value={index } style={{padding: '16px 0'}}>
                                    <OfficeTable {...props}
                                                 expense_id={id}/>
                                </TabPanel>
                            )
                        case 'other':
                            return (
                                <TabPanel value={index } style={{padding: '16px 0'}}>
                                    <OtherTable {...props}
                                                expense_id={id}/>
                                </TabPanel>
                            )
                        default:
                            return null;
                    }
                })
            }
        </TabContext>
    )
}
/*const ExpCompositions2 = (props) => {
    const {id, exp_compositions, formData} = props;
    return (
        exp_compositions.map(it => {
            //console.log('it=========>', it.expenses_amount_type)
            switch (it.expenses_amount_type) {
                case 'salary':
                    return (
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle1">{it.name}</Typography>
                            <SalariesTable {...props} formData={formData}
                                           expense_id={id}/>
                        </Grid>
                    )
                case 'deprecation':
                    return (
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle1">{it.name}</Typography>
                            <DeprecationTable {...props} formData={formData}
                                              expense_id={id}/>
                        </Grid>
                    )
                case 'office':
                    return (
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle1">{it.name}</Typography>
                            <OfficeTable {...props} formData={formData}
                                         expense_id={id}/>
                        </Grid>
                    )
                case 'other':
                    return (
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle1">{it.name}</Typography>
                            <OtherTable {...props} formData={formData}
                                        expense_id={id}/>
                        </Grid>
                    )
                default:
                    return null;
            }
        })
    )
}*/
export const ExpTablesEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    // const classes = useMyStyles();
    const {record, expenses_type_id, nameTable} = props;
    const {id, totals} = record;
    const {exp_compositions, prop_types_belongs} = getConfigFromStorage(expenses_type_id);

    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} alignItems={"center"}>
                            <Typography variant="h5" gutterBottom>{nameTable}</Typography>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={true} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={true} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <SimpleForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'}/>}*/
                toolbar={<PostEditDeleteActions/>}
                initialValues={{expenses_type_id: expenses_type_id, user_id: 1, company_id: 1}}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                {/*<FormTab label="Common">*/}
                    <Box p="1em" fullWidth>
                        <Grid container style={{width: '100%'}} spacing={2}>
                            <Grid item xs={4}>
                                <SelectInput source="year" choices={getListYear()} validate={validateReq} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput source={'name'} label={'Name'} validate={validateReq} fullWidth/>
                                {/*<ReferenceInput source="company_id" reference="companies" label={'Company'}
                                                validate={validateReq}>
                                    <AutocompleteInput resettable optionText="name" fullWidth/>
                                </ReferenceInput>*/}
                            </Grid>
                            <Grid item xs={4}>
                                <ReferenceInput source="group_id" reference="groups" label={'Group'}
                                                validate={validateReq}>
                                    <AutocompleteInput resettable optionText="name" fullWidth/>
                                </ReferenceInput>
                            </Grid>
                        </Grid>

                        <Typography variant="h5">Properties</Typography>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                if (prop_types_belongs) {
                                    return (
                                        <div style={{padding: 16, border: '1px solid #eee', borderRadius: 6}}>
                                            <PropTypesBelongs prop_types_belongs={prop_types_belongs}/>
                                        </div>
                                    )
                                }
                            }}
                        </FormDataConsumer>

                        {/*<Typography variant="h5" style={{marginTop: 16}}>Compositions</Typography>
                        {id
                            ?
                            <FormDataConsumer>
                                {({formData, ...rest}) => {
                                    if (exp_compositions) {
                                        return (
                                            <div style={{padding: 16, border: '1px solid #eee', borderRadius: 6}}>
                                                <ExpCompositions id={id}
                                                                 exp_compositions={exp_compositions}/>
                                            </div>
                                        )
                                    } else return null;
                                }
                                }
                            </FormDataConsumer>
                            :
                            <Typography variant="subtitle1" style={{color: "red"}}>
                                Create a record first, and then edit related tables
                            </Typography>
                        }*/}
                    </Box>
                {/*</FormTab>*/}
            </SimpleForm>

            <Grid container style={{display: "grid", width: '100%', padding: '16px 24px 32px 36px'}} spacing={2}>
                <Typography variant="h5" style={{marginTop: 16}}>Compositions</Typography>
                {id
                    ? exp_compositions
                        ?
                        <div style={{padding: 16, border: '1px solid #eee', borderRadius: 6}}>
                            <ExpCompositions id={id}
                                             exp_compositions={exp_compositions}/>
                        </div>
                        : null
                    :
                    <Typography variant="subtitle1" style={{color: "red"}}>
                        Create a record first, and then edit related tables
                    </Typography>
                }
            </Grid>
                        {totals &&
                <Grid
                    container
                    style={{maxWidth: 1200, marginTop: 0, marginBottom: 3, backgroundColor: 'aliceblue', marginLeft: 3}}
                    spacing={2}
                    justify={"space-around"}
                >
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Contribution 3rs</b>
                        <span style={{color: '#aaa'}}>{formatSpacerNumber(totals.contribution_3rs)}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Grand Total Contributions</b>
                        <span style={{color: '#aaa'}}>{formatSpacerNumber(totals.grand_total_contributions)}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Overall Impact</b>
                        <span style={{color: '#aaa'}}>{totals.overall_impact}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Reach Classification</b>
                        <span style={{color: '#aaa'}}>{totals.reach_classification}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Reach Score</b>
                        <span style={{color: '#aaa'}}>{totals.reach_score}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Relevance Score</b>
                        <span style={{color: '#aaa'}}>{totals.relevance_score}</span>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom: 0, display: "grid"}}>
                        <b style={{color: '#666'}}>Total Score</b>
                        <span style={{color: '#aaa'}}>{totals.total_score}</span>
                    </Grid>
                </Grid>
            }

        </>
    )
};

export default ExpTablesEditCreate
