import * as React from 'react';

import {
    BooleanField,
    DateField, NumberField, useRecordContext,
    DateInput, NumberInput, useGetOne, useTranslate
} from 'react-admin';

import BooleanOnIcon from "@material-ui/icons/CheckCircleOutline";
import BooleanOffIcon from "@material-ui/icons/Close";
import {Button} from "@material-ui/core";
// import AddBoxIcon from '@material-ui/icons/AddToPhotosOutlined';
import CopyClipBoardIcon from "../icons/CopyClipBoardIcon";

import {useRef} from "react";
//AddBox

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {FilterList, FilterListItem, EditButton, useRedirect, useDelete} from 'react-admin';
import {
    //endOfYesterday,
    startOfWeek,
    //subWeeks,
    //addWeeks,
    startOfMonth,
    subMonths,
    subYears,
    startOfDay,
    //endOfDay,
    addDays,
    //addMonths,
    addYears,
    endOfWeek,
    endOfMonth,
} from 'date-fns';
import get from "lodash/get";
import moment from "moment";
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";

export const FilterDataAside2 = (props) => {
    const {fields, name} = props;
    return (
        <FilterList
            label={name}
            icon={<AccessTimeIcon/>}
        >
            <FilterListItem
                label="Tomorrow"
                value={{
                    // [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_gte']: startOfDay(addDays(new Date(), 1)).toISOString(),
                    [fields + '_lte']: startOfDay(addDays(new Date(), 2)).toISOString(),
                }}
            />
            <FilterListItem
                label="Today"
                value={{
                    [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_lte']: startOfDay(addDays(new Date(), 1)).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Today"
                value={{
                    [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_lte']: endOfWeek(new Date()).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="This week"
                value={{
                    [fields + '_gte']: moment(startOfWeek(new Date())).format('YYYY-MM-DD'),//startOfDay(new Date()).toISOString(),
                    [fields + '_lte']: moment(endOfWeek(new Date())).format('YYYY-MM-DD'),//endOfWeek(new Date()).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Next week"
                value={{
                    [fields + '_gte']: moment(addWeeks(startOfWeek(new Date()), 1)).format('YYYY-MM-DD'),//addWeeks(startOfWeek(new Date()), 1).toISOString(),
                    [fields + '_lte']: moment(addWeeks(endOfWeek(new Date()), 1)).format('YYYY-MM-DD'),//addWeeks(endOfWeek(new Date()), 1).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="This month"
                value={{
                    [fields + '_gte']: startOfMonth(new Date()).toISOString(),
                    // target_date_lte: startOfDay(addDays(new Date(), 1)).toISOString(),
                    [fields + '_lte']: endOfMonth(new Date()).toISOString(),
                }}
            />
            <FilterListItem
                label="Custom"
                value={{
                    [fields + '_gte']: subYears(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),

                    [fields + '_lte']: addYears(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),
                }}
            />
        </FilterList>
    )
}
export const FilterDataAside = (props) => {
    const {fields, name} = props;
    return (
        <FilterList
            label={name}
            icon={<AccessTimeIcon/>}
        >
            <FilterListItem
                label="Today"
                value={{
                    [fields + '_gte']: startOfDay(new Date()).toISOString(), //endOfYesterday().toISOString(),
                    [fields + '_lte']: startOfDay(addDays(new Date(), 1)).toISOString(),
                }}
            />
            <FilterListItem
                label="This week"
                value={{
                    [fields + '_gte']: startOfWeek(new Date()).toISOString(),
                    //target_date_lte: startOfDay(addDays(new Date(), 1)).toISOString(),//endOfDay(new Date()).toISOString(),
                    [fields + '_lte']: endOfWeek(new Date()).toISOString(),//endOfDay(new Date()).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Last week"
                value={{
                    [fields + '_gte']: subWeeks(
                        startOfWeek(new Date()),
                        1
                    ).toISOString(),
                    [fields + '_lte']: startOfWeek(new Date()).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="This month"
                value={{
                    [fields + '_gte']: startOfMonth(new Date()).toISOString(),
                    // target_date_lte: startOfDay(addDays(new Date(), 1)).toISOString(),
                    [fields + '_lte']: endOfMonth(new Date()).toISOString(),
                }}
            />
            {/*<FilterListItem
                label="Last month"
                value={{
                    [fields + '_gte']: subMonths(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),
                    [fields + '_lte']: startOfMonth(new Date()).toISOString(),
                }}
            />*/}
            <FilterListItem
                label="Earlier"

                value={{
                    [fields + '_gte']: subYears(
                        startOfMonth(new Date()),
                        20
                    ).toISOString(),

                    [fields + '_lte']: subMonths(
                        startOfMonth(new Date()),
                        1
                    ).toISOString(),
                }}
            />
        </FilterList>
    )
}

export const GetFieldById = (props) => {
    const {id, resource, field, bold = true} = props;
    let title = "";
    const {data, loaded} = useGetOne(resource, id);
    if (loaded && data) title = get(data, field, null);
    //console.log('data=============>', data)
    return (
        <span style={{fontWeight: bold && 'bold'}}>
            {typeof title === "boolean"
                ? title ? field : ''
                : title
            }
        </span>
    );
}

export const DateInputComp = (props) => {
    // const {source} = props;
    return (
        <DateInput

            {...props}
            // source={source}
            // options={{ format: "MM/DD/YYYY", clearable: true }}
            locales="en-Us"
        />
    )
}
export const DateFieldComp = (props) => {
    /*
    moment().format('MMMM Do YYYY, h:mm:ss a'); // December 14th 2022, 2:47:57 pm
    moment().format('dddd');                    // Wednesday
    moment().format("MMM Do YY");               // Dec 14th 22
    moment().format('YYYY [escaped] YYYY');     // 2022 escaped 2022
    moment().format();
    */
    //console.log('DateFieldComp=========>', props)
    const {source, showTime = false, locale = 'en'} = props;
    let record = useRecordContext(props);
    let dt = get(record, source, '');

    if (showTime) dt = dt ? moment(dt).format("D-MMM-YY, h:mm\u00a0a") : '';
    else dt = dt ? moment(dt).format("D-MMM-YY") : '';
    if (locale === 'ru') {
        return (
            <DateField
                showTime={showTime}
                {...props}
            />
        )
    } else {
        return (
            <span>{dt}</span>
        )
        /*return (
            <DateField
                showTime={showTime}
                {...props}
                // source={source}
                locales="en-Us"
                // options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                options={{year: 'numeric', month: 'short', day: 'numeric'}}
                //showTime={true}
            />
        )*/
    }
}


export const formatSpacerNumber = (str, delim = ',', fix = 2) => {//\u00a0
    if (str) {
        let st = parseFloat(str).toFixed(fix)
        if (st >= 1000) return st.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delim);
        else return st;
    } else return '';
};
export const FloatNumber = (props) => {
    const {source, fix = 2, delim = ','} = props;
    const record = useRecordContext(props);
    let par = get(record, source, '');
    if (par) par = formatSpacerNumber(par, delim, fix);
    return (
        <span>{par ? par : null}</span>
    );
}


export const NumberInputFloatComp = (props) => {
    return (
        <NumberInput
            {...props}
            format={v => parseFloat(v)
            }
            // helperText={'Error Error'}
            //format={v => v * 100}
            //parse={v => parseFloat(v)}
        />
    )
}
export const NumberFieldComp = (props) => {
    const {bold} = props;
    return (
        <NumberField
            {...props}
            // source={source}
            locales="en-Us"
            options={{style: 'currency', currency: 'USD'}}
            style={{fontWeight: bold && 'bold'}}
        />
    )
}

function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

const handleClickCopyClipBoard = (event, text, tooltipText) => {
    event.preventDefault();
    event.stopPropagation();
    tooltipText.current.style.visibility = "visible";
    setTimeout(function () {
        if (tooltipText.current) {
            tooltipText.current.style.visibility = "hidden";
        }
    }, 1500);

    if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        unsecuredCopyToClipboard(text);
    }
}

const handleClickCopyClipBoardId = (event, source, tooltipText) => {
    event.preventDefault();
    event.stopPropagation();
    tooltipText.current.style.visibility = "visible";
    setTimeout(function () {
        if (tooltipText.current) tooltipText.current.style.visibility = "hidden";
    }, 1500);
    let res = document.getElementById(source);
    if (res) window.navigator.clipboard.writeText(res.value)
    else window.navigator.clipboard.writeText('')
}

export const CopyClipBoard = (props) => {
    //const record = useRecordContext();
    const {source} = props;
    const tooltipText = useRef();
    return (
        <>
            <span
                ref={tooltipText}
                className="tooltipText"
                style={{marginLeft: '-128px', marginTop: '-18px'}}
            >
                Copied to clipboard
            </span>
            <CopyClipBoardIcon
                onClick={(event) => handleClickCopyClipBoardId(event, source, tooltipText)}
                style={{
                    // color: "#444!important",
                    // backgroundColor: 'green',
                    cursor: "pointer",
                    position: "absolute",
                    marginLeft: '-28px',
                    marginTop: '22px',
                }}
                title='write Text'
            />
        </>
    );
};

export const TextFieldComp = (props) => {
    const record = useRecordContext();
    const {source, itsOriText = false, copyClipBoard = false} = props;
    const text = record &&
    itsOriText
        ? itsOriText
        : record[source];

    const tooltipText = useRef();
    return (
        <>
            {copyClipBoard && <span ref={tooltipText} className="tooltipText">Copy to clipboard: {text}</span>}
            <span
                style={{margin: 16, padding: 16}}
                {...props}
            >
            {text}
        </span>
            {copyClipBoard &&
                <CopyClipBoardIcon
                    onClick={(event) => handleClickCopyClipBoard(event, text, tooltipText)}
                    style={{cursor: "pointer", position: "absolute", marginLeft: '2px'}}
                    title='write Text'
                />
            }
        </>
    );
};

export const MyEditButton = (props) => {
    const {path, fieldId} = props;
    const record = useRecordContext();
    const redirect = useRedirect()
    let id = get(record, fieldId, '')
    //console.log('passenger=======>', record)
    return (
        <EditButton label=" " onClick={() => redirect(`/${path}/${id}`)}/>
    )
}

export const MyDeleteButton = (props) => {
    const {resource, fieldId} = props;
    const record = useRecordContext();
    let id = get(record, fieldId, '')

    const [deleteOne, {loaded}] = useDelete(
        resource,
        id
    );
    const handleClick = () => {
        deleteOne(resource, id);
    }

    if (loaded) {
        console.log(id, 'error || isLoading=======>', loaded)
    }
    return (
        <Button label="DEL" onClick={handleClick}>DEL</Button>
    )
}

export const TextFieldButton = (props) => {
    const record = useRecordContext();
    const tooltipText = useRef();
    const {
        source, source2 = '', source3 = '',
        title = '', itsOriText = false, copyClipBoard = false,
        pasw = false, delim = '\u00a0',
        backgroundColor = null,//'#eee',
        if_field = null,
        if_value = null,
        bold = true,
    } = props;

    let tit = get(record, title, '');
    let arr = [];
    arr.push(get(record, source, ''));
    if (source2) arr.push(get(record, source2, ''));
    if (source3) arr.push(get(record, source3, ''));

    const text = record &&
    itsOriText
        ? itsOriText ? itsOriText.replaceAll(' ', '\u00a0') : ''
        : String(arr.join(delim).replaceAll(' ', '\u00a0'));

    let alarm = false;
    if (if_field && if_value) {
        alarm = get(record, if_field, false)
        if (alarm !== if_value) alarm = true; else alarm = false;
    }
    return (
        <>
            {text && copyClipBoard && <span ref={tooltipText} className="tooltipText">Copy to clipboard: {text}</span>}
            <span
                {...props}
                style={{
                    color: alarm && 'red',
                    fontWeight: bold && 'bold',
                    padding: copyClipBoard ? '8px 32px 8px 12px' : '8px 12px',
                    backgroundColor: backgroundColor,
                    borderRadius: '8px',
                    marginLeft: -12,
                }}
                title={tit}
            >
            {pasw
                ? '****'
                : alarm ? `${text} - Not matching the selected Customer` : text}
        </span>
            {text && copyClipBoard &&
                <CopyClipBoardIcon
                    onClick={(event) => handleClickCopyClipBoard(event, text, tooltipText)}
                    style={{cursor: "pointer", position: "absolute", marginLeft: "-28px", color: 'gray'}}
                    title='write Text'
                />
            }
        </>
    );
};

export const TextFieldBold = ({source}) => {
    const record = useRecordContext();
    return (
        <span style={{fontWeight: 'bold'}}>
            {record && record[source]}
        </span>
    );
};

export const BooleanFieldComp = (props) => {
    // const {source} = props;
    return (
        <BooleanField
            style={{display: "grid"}}
            {...props}
            // source={source}
            valueLabelTrue={'YES'} valueLabelFalse={'NO'}
            TrueIcon={BooleanOnIcon} FalseIcon={BooleanOffIcon}
        />
    )
}

const getSrc = (record, source, itsOriSrc) => {
    let src = '';
    if (itsOriSrc === true) src = source;
    else {
        src = source.split('.');
        if (src.length > 1) {
            src = record[src[0]] && record[src[0]][src[1]] ? record[src[0]][src[1]] : '';
        } else src = record[src[0]];
        //src = `${record[source]}`;
    }
    return src
}
export const VideoFieldComp = (props) => {
    const record = useRecordContext(props);
    const {height = 24, source, itsOriSrc = false} = props;
    let src = getSrc(record, source, itsOriSrc);
    return (
        <video
            src={src}
            controls
            width="auto"
            height={height}
        >
        </video>
    )
}
export const ImageFieldComp = (props) => {
    const record = useRecordContext(props);
    const {width = 64, height, avatar, source, alt = '', itsOriSrc = false} = props;
    let src = getSrc(record, source, itsOriSrc);
    //let src = get(record, source, null);
    // console.log('src======>', src)
    if (src) {
        return (
            <img
                alt={alt}
                style={{borderRadius: avatar ? "50%" : "4px"}}
                src={`${src}`}
                width={width}
                height={avatar
                    ? width
                    : height
                        ? height
                        : "auto"}
            >
            </img>
        )
    } else return null;
}
export const ButtonFilterAside = (props) => {
    const translate = useTranslate();
    const {showAsside} = props;
    const [nameButton, SetNameButton] = React.useState(translate(showAsside ? 'myLocal.hide_filter' : 'myLocal.show_filter'));
    const [visibleButton, SetVisibleButton] = React.useState(showAsside);
    const handleClick = (url) => {
        //console.log('url===1======>', getCodeFromUrl(url))
        const bl = document.getElementById('aside_card');
        if (bl) {
            if (visibleButton) {
                bl.style.width = '0'
                SetVisibleButton(false)
                SetNameButton(translate('myLocal.show_filter'));
                bl.animate([
                    // keyframes
                    {width: '12em'},
                    {width: '0'},
                ], {
                    // timing options
                    duration: 300,
                    //iterations: Infinity
                })
            } else {
                bl.style.width = '12em'
                SetVisibleButton(true)
                SetNameButton(translate('myLocal.hide_filter'));
                bl.animate([
                    // keyframes
                    {width: '0'},
                    {width: '12em'},
                ], {
                    // timing options
                    duration: 300,
                    //iterations: Infinity
                })
            }
        }
    };

    return (
        <Button onClick={handleClick} color={"primary"}>{nameButton}</Button>
    );
}

export function setConfigToStorage(set = false) {
    /*useGetList('exp_compositions',
        {page: 1, perPage: 99},
        {field: 'order', order: 'DESC'},
        //{year___eq: `${year1}`}
    );
    useGetList('prop_types_belongs',
        {page: 1, perPage: 99},
        {field: 'order', order: 'DESC'},
        //{year___eq: `${year1}`}
    );
    let res = useSelector((state) => state.admin.resources.exp_compositions);
    let {data: exp_compositions} = res || {};
    exp_compositions = exp_compositions ? Object.values(exp_compositions) : null;
    res = useSelector((state) => state.admin.resources.prop_types_belongs);
    let {data: prop_types_belongs} = res || {};
    prop_types_belongs = prop_types_belongs ? Object.values(prop_types_belongs) : null;

    if(exp_compositions) localStorage.setItem('swiss_exp_compositions', JSON.stringify(exp_compositions));
    if(prop_types_belongs) localStorage.setItem('swiss_prop_types_belongs', JSON.stringify(prop_types_belongs));*/
    let exp = JSON.parse(localStorage.getItem("swiss_exp_compositions"));
    if (!exp || set) {
        const getCompositions = async (year) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `api/admin/expenses_compositions?limit=99&ordering=-order&skip=0`;
            try {
                const response = await httpClient(`${url}${report_url}`);
                if (response.status >= 200 && response.status <= 300) {
                    //console.log('expenses_compositions=======>', response.json.results);
                    if (response.json.results)
                        localStorage.setItem('swiss_exp_compositions', JSON.stringify(response.json.results));
                }
            } catch (err) {
                //console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
                //refresh();
            }
        };
        getCompositions();
    }
    exp = JSON.parse(localStorage.getItem("swiss_prop_types_belongs"));
    if (!exp || set) {
        const getTypesBelongs = async (year) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `api/admin/properties_types_belongs?limit=99&ordering=-order&skip=0`;
            try {
                const response = await httpClient(`${url}${report_url}`);
                if (response.status >= 200 && response.status <= 300) {
                    //console.log('properties_types_belongs=======>', response.json.results);
                    if (response.json.results)
                        localStorage.setItem('swiss_prop_types_belongs', JSON.stringify(response.json.results));
                }
            } catch (err) {
                //console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
                //refresh();
            }
        };
        getTypesBelongs();
    }
    exp = JSON.parse(localStorage.getItem("swiss_prop_types_belongs"));
    if (!exp || set) {
        const getExpTypes = async (year) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `api/admin/expenses_types?limit=99&ordering=-order&skip=0`;
            try {
                const response = await httpClient(`${url}${report_url}`);
                if (response.status >= 200 && response.status <= 300) {
                    //console.log('properties_types_belongs=======>', response.json.results);
                    if (response.json.results)
                        localStorage.setItem('swiss_exp_types', JSON.stringify(response.json.results));
                }
            } catch (err) {
                //console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
                //refresh();
            }
        };
        getExpTypes();
    }
}

export function clearLocalStorage(setConfig = true) {
    localStorage.removeItem('swiss_exp_compositions');
    localStorage.removeItem('swiss_prop_types_belongs');
    localStorage.removeItem('swiss_exp_types');
    if (setConfig) {
        localStorage.removeItem('preferences');
        setTimeout(function () {
            setConfigToStorage(true);
        }, 500);
    }
}

export function getConfigFromStorage(expenses_type_id) {
    let exp_compositions = JSON.parse(localStorage.getItem("swiss_exp_compositions"));
    let prop_types_belongs = JSON.parse(localStorage.getItem("swiss_prop_types_belongs"));
    let exp_types = JSON.parse(localStorage.getItem("swiss_exp_types"));

    exp_compositions = exp_compositions ? exp_compositions.filter(x => x.expenses_type_id === expenses_type_id) : null;
    prop_types_belongs = prop_types_belongs ? prop_types_belongs.filter(x => x.expenses_type_id === expenses_type_id) : null;
    exp_types = exp_types ? exp_types.find(x => x.id === parseInt(expenses_type_id)) : null;

    return {exp_compositions, prop_types_belongs, exp_types}
}

async function readFileAsDataURL(file) {
    const result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    //console.log(result_base64); // aGV5IHRoZXJl...

    return result_base64;
}

export const transformBase64 = async (data) => {
    //async function transform(data) {
    let dt = data;
    for (const [key, value] of Object.entries(data)) {
        const {rawFile} = value || {};
        if (rawFile && rawFile instanceof File) {
            const {type} = rawFile || {};
            let dataURL = await readFileAsDataURL(rawFile);
            dt[key] = {rawFile: {type: type}, src: dataURL}
        }
    }
    //console.log('it=========>', dt)
    return dt;
}


// export default DateFieldComp;
// export default NumberFieldComp;
