import * as React from 'react';

import {
    Edit,
} from 'react-admin';

import PostEditActions from "./PostEditActions";
import {EditDialog} from "@react-admin/ra-form-layout";

const EditComponentAdmin = (props) => {
    const {children, editDialog} = props;

    if (editDialog)
        return (
            <EditDialog {...props}
                maxWidth="md"
                fullWidth
                  undoable={false} //Подтверждение удаления
                  actions={<PostEditActions/>}
            >
                {children}
            </EditDialog>
        )
    else
        return (
            <Edit {...props}
                //fullWidth
                  undoable={false} //Подтверждение удаления
                  actions={<PostEditActions/>}
            >
                {children}
            </Edit>
        )
};
export default EditComponentAdmin;
