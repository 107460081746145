/*export const accountTypes = {
    man: 'MAN',
    woman: 'WOMAN',
    couple: 'COUPLE',

    isCouple: (type) => (type === accountTypes.couple),
    showMan: (type) => ([accountTypes.man, accountTypes.couple].includes(type)),
    showWoman: (type) => ([accountTypes.woman, accountTypes.couple].includes(type)),

    showManFields: (types) => (types && (types.includes(accountTypes.man) || types.includes(accountTypes.couple))),
    showWomanFields: (types) => (types && (types.includes(accountTypes.woman) || types.includes(accountTypes.couple))),
}*/
import moment from "moment";

export const getStatusById = (id, statuses) => {
    let res = statuses.find(x => x.id === id);
    //console.log('id, statuses===>', id, statuses, res);
    if (res)
        return {
            id: res.id,
            name: res.name,
            short_name: res.short_name ? res.short_name : res.id,
            img: res.image ? res.image : '',
            color: res.color ? res.color : '',
        };
    else return {id: false, name: '', short_name: '', img: '', color: ''};
    // for (const x of statuses) { if (x.id===id) return `${x.image}`}
}
//====================== Common ====================================
/*
export const statusArticleSection = [
    {id: 0, name: 'INACTIVE', short_name: 'INACTIVE', image: '👎'},
    {id: 1, name: 'ACTIVE', short_name: 'ACTIVE', image: '👍'},
]
*/
export const expType = [
    {id: 'direct_support', name: 'direct_support'},
    {id: 'infrastructure', name: 'infrastructure'},
    {id: 'animal_facilities', name: 'animal_facilities'},
    {id: 'programs', name: 'programs'},
    {id: 'educations', name: 'educations'},
    {id: '', name: 'Другое'},
]
export const styleProperty = [
    {id: 'string', name: 'string'},
    {id: 'int', name: 'int'},
    {id: 'float', name: 'float'},
    {id: 'date', name: 'date'},
]
export const expAmountType = [
    {id: 'other', name: 'other', image: '👍'},
    {id: 'salary', name: 'salary', image: '👎'},
    {id: 'deprecation', name: 'deprecation', image: '👎'},
    {id: 'office', name: 'office', image: '👎'},
]
export const listYear = [
    {id: 2023, name: '2023'},
    {id: 2024, name: '2024'},
    {id: 2025, name: '2025'},
    {id: 2026, name: '2026'},
    {id: 2027, name: '2027'},
    {id: 2028, name: '2028'},
    {id: 2029, name: '2029'},
    {id: 2030, name: '2030'},
    {id: 2031, name: '2031'},
    {id: 2032, name: '2032'},
    {id: 2033, name: '2033'},
]

export const getListYear = () => {
    let dt = parseInt(moment(new Date()).format('YYYY'));
    //console.log('id, statuses===>', id, statuses, res);
    let i = 0;
    let res = [];
    while (i < 10){
        res.push({id: String(dt-i), name: String(dt-i)}); i++;
    }
    return res;
}

export const commonYesNo = [
    {id: 'Y', name: 'YES', image: '👍'},
    {id: 'N', name: 'NO', image: '👎'},
]
export const commonTrueFalse = [
    {id: true, name: 'YES', image: '👍'},
    {id: false, name: 'NO', image: '👎'},
]
//====================== RichTextInput =========================

export const toolbarRichTextInput = [
    // [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [{'font': []}],

    ['video'],
    // ['insert-code', 'code-insert'],
    ['image', 'link'],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'align': []}],

    ['clean']                                         // remove formatting button
    /*
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']
    */
]