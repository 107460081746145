import * as React from 'react';
import {
    TextField, ReferenceManyField, NumberInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {FloatNumber, NumberInputFloatComp} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/

const ListEditForm = (props) => {
    const {expense_id} = props;
    /*const {getValues} = useFormContext();
    console.log(booking_id, '=====OK======>', getValues("id"))*/
    return (
        <RowForm  {...props} initialValues={{expense_id: expense_id}}>
            <NumberInput source="space_m2" label={'Space (m2)'} validate={validateReq}/>
            <NumberInputFloatComp source="contribution" label={'Contribution'} validate={validateReq}/>
        </RowForm>
    )
};

const OfficeTable = props => {
    const {expense_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    return (
        <div style={{width: "max-content", minWidth: 800, marginBottom: 16}}>
            <ReferenceManyField
                fullWidth
                label=" "
                reference="offices"
                target="expense_id"
            >
                <EditableDatagrid
                    classes={{headerCell: classes.headerCellVerticalCenter, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={true}
                    /*onChange={(data) => {
                        console.log('===fdsBookingForm==========>')
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    }}*/
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} expense_id={expense_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} expense_id={expense_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <TextField source="space_m2" label={'Space (m2)'} textAlign={"right"}/>
                    <FloatNumber source={`contribution`} label={'Contribution'}
                                 textAlign={"right"}/>
                </EditableDatagrid>
            </ReferenceManyField>
        </div>
    )
}
export default OfficeTable;